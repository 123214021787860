<template>
  <!-- Page Content  -->
  <b-container fluid>
    <b-row>
      <!-- BEGIN:Table -->
      <b-col md="12">
        <iq-card
          bodyClass="relative-background"
          class="iq-card-block iq-card-stretch iq-card-height"
        >
          <template v-slot:headerTitle>
            <h4 class="card-title">Tambah Data IA</h4>
          </template>
          <template v-slot:body>
            <div class="d-inline-block float-right">
              <router-link
                class="btn btn-secondary dark-btn-primary"
                :to="{ name: 'kerjasama.jenisDokumen' }"
                role="button"
                ><i class="ri-arrow-left-line"></i>Kembali</router-link
              >
            </div>
            <div class="align-items-center mt-lg-5">
              <div>
                <b-form-group>
                  <label for="nomorSuratUt">Nomor Surat UT:</label
                  ><span class="text-danger ml-1">*</span>
                  <input-primary
                    id="nomorSuratUt"
                    v-model="form.nomorSuratUt"
                    required
                    placeholder="Nomor Surat UT"
                  />
                </b-form-group>
                <b-form-group>
                  <label for="nomorSuratMitra">Nomor Surat Mitra:</label
                  ><span class="text-danger ml-1"></span>
                  <input-primary
                    id="nomorSuratMitra"
                    v-model="form.nomorSuratMitra"
                    placeholder="Nomor Surat Mitra"
                  />
                </b-form-group>
                <b-form-group>
                  <label for="tanggalSurat">Tanggal Surat:</label
                  ><span class="text-danger ml-1">*</span>
                  <flat-pickr
                    id="tanggalSurat"
                    ref="refTanggalSurat"
                    class="form-control bg-white"
                    :config="basicConfig"
                    v-model="form.tanggalSurat"
                    placeholder="Tanggal Surat"
                    required
                  ></flat-pickr>
                </b-form-group>
                <b-row class="mt-4">
                  <!--  PIHAK PERTAMA -->
                  <b-col>
                    <h4 class="mb-2">PIHAK PERTAMA</h4>
                    <b-form-group>
                      <label for="namaPihakSatu">Nama Pihak Pertama:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="namaPihakSatu"
                        v-model="form.namaPihakSatu"
                        placeholder="Nama Pihak Pertama"
                        required
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="jabatanPihakSatu">Jabatan:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="jabatanPihakSatu"
                        v-model="form.jabatanPihakSatu"
                        placeholder="Jabatan"
                        required
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="instansiPihakSatu">Instansi:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="instansiPihakSatu"
                        v-model="form.instansiPihakSatu"
                        placeholder="Instansi"
                        required
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="alamatPihakSatu">Alamat:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="alamatPihakSatu"
                        v-model="form.alamatPihakSatu"
                        required
                        type="textarea"
                        max-length="255"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- PIHAK KEDUA -->
                  <b-col>
                    <h4 class="mb-2">PIHAK KEDUA</h4>
                    <b-form-group>
                      <label for="namaPihakDua">Nama Pihak Kedua:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="namaPihakDua"
                        v-model="form.namaPihakDua"
                        placeholder="Nama Pihak Kedua"
                        required
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="jabatanPihakDua">Jabatan:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="jabatanPihakDua"
                        v-model="form.jabatanPihakDua"
                        placeholder="Jabatan"
                        required
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="instansiPihakDua">Instansi:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="instansiPihakDua"
                        v-model="form.instansiPihakDua"
                        placeholder="Instansi"
                        required
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="alamatPihakDua">Alamat:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="alamatPihakDua"
                        v-model="form.alamatPihakDua"
                        type="textarea"
                        max-length="255"
                        required
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="mt-4">
                  <!-- ---- -->
                </b-row>
                <b-row class="mt-4">
                  <!-- KEGIATAN -->
                  <b-col>
                    <h4 class="mb-2">KEGIATAN</h4>
                    <b-form-group>
                      <label for="namaKegiatan">Nama Kegiatan:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="namaKegiatan"
                        v-model="form.namaKegiatan"
                        required
                        placeholder="Nama Kegiatan"
                        max-length="255"
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="judulPks">Judul Kegiatan:</label
                      ><span class="text-danger ml-1"></span>
                      <input-primary
                        id="judulPks"
                        v-model="form.judulPks"
                        placeholder="Judul Kegiatan"
                        max-length="255"
                        required
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-form-group>
                    <label for="tanggalAcara">Tangga Mulai Kegiatan:</label
                    ><span class="text-danger ml-1">*</span>
                    <flat-pickr
                      id="tanggalAcara"
                      ref="refTanggalAcara"
                      class="form-control bg-white"
                      :config="basicConfig"
                      v-model="form.tanggalAcara"
                      placeholder="Tanggal Kegiatan"
                      @input="inputTanggalPks"
                      required
                    ></flat-pickr>
                  </b-form-group>
                  <div v-if="!form.tanggalAcara">
                    <b-form-group>
                      <label for="tanggalAcara">Tanggal Selesai Kegiatan:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        class="bg-light cursor-not-allowed"
                        v-b-tooltip="
                          'Masukkan Tanggal Akhir Kegiatan lebih dulu'
                        "
                        placeholder="Masukkan Tanggal Akhir Kegiatan lebih dulu"
                        disabled
                      />
                    </b-form-group>
                  </div>
                  <div v-else>
                    <b-form-group>
                      <label for="tanggalAkhirAcara"
                        >Tanggal Selesai Kegiatan:</label
                      ><span class="text-danger ml-1">*</span>
                      <flat-pickr
                        id="tanggalAkhirAcara"
                        ref="refTanggalAkhirAcara"
                        class="form-control bg-white"
                        :config="basicConfig"
                        v-model="form.tanggalAkhirAcara"
                        placeholder="Tanggal Selesai Kegiatan"
                        :isError="!isValidTanggalAkhir"
                        @input="inputAkhirTanggal"
                        required
                      ></flat-pickr>
                      <collapse-transition>
                        <div
                          class="text-danger mb-2"
                          v-show="isError.berlakuPks.validBerlakuPks"
                        >
                          Tanggal Selesai Kegiatan tidak bisa kurang dari
                          Tanggal Acara.
                        </div>
                      </collapse-transition>
                    </b-form-group>
                  </div>
                  <!-- <b-col>
                    <b-form-group>
                      <label for="tanggalAkhirAcara">Tanggal Selesai Kegiatan:</label
                      ><span class="text-danger ml-1">*</span>
                      <flat-pickr
                        id="tanggalAkhirAcara"
                        ref="refTanggalAkhirAcara"
                        class="form-control bg-white"
                        :config="basicConfig"
                        v-model="form.tanggalAkhirAcara"
                        placeholder="Tanggal Selesai Kegiatan"
                        required
                      ></flat-pickr>
                    </b-form-group>
                  </b-col> -->
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group>
                      <label for="tempatAcara">Tempat Kegiatan:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="tempatAcara"
                        v-model="form.tempatAcara"
                        placeholder="Tempat Kegiatan"
                        required
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="lokasiDokumenIa">File Dokumen:</label
                      ><span class="text-danger ml-1">*</span>
                      <b-form-file
                        accept=".pdf"
                        placeholder="File Dokumen"
                        id="lokasiDokumenIa"
                        ref="refLokasiDokumenIa"
                        v-model="form.lokasiDokumenIa"
                        @input="inputUploadDokumen"
                        required
                      ></b-form-file>
                    </b-form-group>
                  </b-col>
                </b-row>

                <!-- Validasi Form -->
                <b-row class="mt-5">
                  <b-col>
                    <transition name="hint" appear>
                      <div class="text-justify iq-bg-primary text-dark p-4">
                        <div class="w-full flex flex-wrap">
                          <!-- Status lengkap form -->
                          <div>
                            <h5 class="font-weight-bold">
                              Data Wajib Yang Belum Terisi:
                            </h5>
                            <div v-if="formValidation.errors.length > 0">
                              <div
                                v-for="error in formValidation.errors"
                                :key="error.content"
                              >
                                <div class="p-1">
                                  <span
                                    class="
                                      cursor-pointer
                                      font-weight-bold
                                      text-primary
                                    "
                                    @click="inputFocus(error.model)"
                                    >• {{ error.content }}</span
                                  >
                                </div>
                              </div>
                            </div>
                            <div v-if="!formValidation.errors.length > 0">
                              <div class="text-success">Form Sudah Lengkap</div>
                            </div>
                          </div>
                        </div>
                        <div class="mt-4 text-primary">
                          * Cek kembali pengisian form jika tidak dapat
                          menyimpan data
                        </div>
                      </div>
                    </transition>
                  </b-col>
                </b-row>
                <!-- Button -->
                <b-row class="mt-5">
                  <b-col>
                    <router-link
                      class="btn btn-secondary dark-btn-primary btn-block"
                      :to="{ name: 'kerjasama.jenisDokumen' }"
                      role="button"
                      ><i class="ri-arrow-left-line"></i>Kembali</router-link
                    >
                  </b-col>
                  <b-col>
                    <b-button
                      class="btn-block"
                      variant="success"
                      type="submit"
                      @click="createData"
                      :disabled="!isValidForm"
                      ><i class="ri-save-line"></i>Simpan</b-button
                    >
                  </b-col>
                </b-row>
              </div>
              <!-- </b-form> -->
            </div>
          </template>
        </iq-card>
      </b-col>
      <!-- ./END:Table -->
    </b-row>
  </b-container>
</template>
<script>
import axios from 'axios'
import { mapState } from 'vuex'
// import moment from 'moment'

export default {
  name: 'kerjasama.ia.tambah',
  computed: {
    ...mapState('authService', ['token']),
    isValidForm() {
      const status =
        this.form.nomorSuratUt &&
        this.form.tanggalSurat &&
        // PIHAK PERTAMA
        this.form.namaPihakSatu &&
        this.form.jabatanPihakSatu &&
        this.form.instansiPihakSatu &&
        this.form.alamatPihakSatu &&
        // PIHAK KEDUA
        this.form.namaPihakDua &&
        this.form.jabatanPihakDua &&
        this.form.instansiPihakDua &&
        this.form.alamatPihakDua &&
        // KEGIATAN
        this.form.namaKegiatan &&
        this.form.judulPks &&
        this.form.tanggalAcara &&
        this.form.tanggalAkhirAcara &&
        this.form.tempatAcara &&
        this.form.lokasiDokumenIa
      return status
    },
    // Validasi Form
    formValidation() {
      const errors = []
      // const models = []
      let i = 0
      const formRequired = [
        this.form.nomorSuratUt,
        this.form.tanggalSurat,
        // PIHAK PERTAMA
        this.form.namaPihakSatu,
        this.form.jabatanPihakSatu,
        this.form.instansiPihakSatu,
        this.form.alamatPihakSatu,
        // PIHAK KEDUA
        this.form.namaPihakDua,
        this.form.jabatanPihakDua,
        this.form.instansiPihakDua,
        this.form.alamatPihakDua,
        // KEGIATAN
        this.form.namaKegiatan,
        this.form.judulPks,
        this.form.tanggalAcara,
        this.form.tanggalAkhirAcara,
        this.form.tempatAcara,
        this.form.lokasiDokumenIa
      ]
      for (const condition of this.requiredForm) {
        if (['', null].includes(formRequired[i++])) {
          errors.push(condition)
        }
      }
      if (errors.length === 0) {
        return { valid: true, errors }
      } else {
        return { valid: false, errors }
      }
    },
    isValidTanggalAkhir() {
      const { validBerlakuPks } = this.isError.berlakuPks
      return !validBerlakuPks
    },
    isValidBerakhirPks() {
      const { validBerakhirPksFromTanggalKs } = this.isError.berakhirPks
      return !validBerakhirPksFromTanggalKs
    }
  },
  mounted() {
    this.innitData()
  },
  methods: {
    /**
     * Focus input
     */
    inputFocus(data) {
      const refChar = 'ref' // ref = input datalist, !ref = input text
      if (['refTanggalSurat'].includes(data)) {
        /** Flatpickr focus */
        this.$refs[data].$el.nextSibling.focus()
        this.$refs[data].$el._flatpickr.open()
      } else if (['nomorSuratUt'].includes(data)) {
        /** Button open modal focus */
        window.scrollTo(0, 0)
        document.getElementById(data).click()
      } else if (['reftanggalAcara'].includes(data)) {
        /** Flatpickr focus */
        this.$refs[data].$el.nextSibling.focus()
        this.$refs[data].$el._flatpickr.open()
      } else if (data.includes(refChar)) {
        /** Dropdown focus */
        this.$refs[data].focus()
      } else {
        /** Input text focus */
        document.getElementById(data).focus()
      }
    },

    inputTanggalPks() {
      this.form.tanggalAkhirAcara = null
    },
    inputAkhirTanggal() {
      // -- Input Tanggal --
      // const berlakuPks = moment(this.form.berlakuPks).format('DD-MM-YYYY')
      const inputBerlakuPks = new Date(this.form.tanggalAkhirAcara)
      // -- Tanggal PKS --
      // const tglPks = moment(this.form.tglPks).format('DD-MM-YYYY')
      const inputTglPks = new Date(this.form.tanggalAcara)
      if (
        inputBerlakuPks.setHours(0, 0, 0, 0) < inputTglPks.setHours(0, 0, 0, 0)
      ) {
        this.isError.berlakuPks.validBerlakuPks = true
      } else this.isError.berlakuPks.validBerlakuPks = false
    },
    /**
     * Rules input email
     */
    async inputUploadDokumen() {
      if (this.form.lokasiDokumenIa?.type !== 'application/pdf') {
        this.form.lokasiDokumenIa = null
        this.$toast.error('Dokumen harus format PDF (.pdf)')
      }
    },
    /**
     * CREATE => Data
     */
    async createData() {
      // console.log('form: ', this.form)
      // this.$toast.info('Sedang dalam pengembangan')

      const bodyFormData = new FormData()
      bodyFormData.append('nomorSuratUt', this.form.nomorSuratUt)
      bodyFormData.append(
        'nomorSuratMitra',
        this.form.nomorSuratMitra ? this.form.nomorSuratMitra : null
      )
      bodyFormData.append('tanggalSurat', this.form.tanggalSurat)

      bodyFormData.append('namaPihakSatu', this.form.namaPihakSatu)
      bodyFormData.append('jabatanPihakSatu', this.form.jabatanPihakSatu)
      bodyFormData.append('instansiPihakSatu', this.form.instansiPihakSatu)
      bodyFormData.append('alamatPihakSatu', this.form.alamatPihakSatu)

      bodyFormData.append(
        'namaPihakDua',
        this.form.namaPihakDua ? this.form.namaPihakDua : null
      )
      bodyFormData.append(
        'jabatanPihakDua',
        this.form.jabatanPihakDua ? this.form.jabatanPihakDua : null
      )
      bodyFormData.append(
        'instansiPihakDua',
        this.form.instansiPihakDua ? this.form.instansiPihakDua : null
      )
      bodyFormData.append(
        'alamatPihakDua',
        this.form.alamatPihakDua ? this.form.alamatPihakDua : null
      )

      bodyFormData.append('namaKegiatan', this.form.namaKegiatan)
      bodyFormData.append('judulPks', this.form.judulPks)
      bodyFormData.append('tanggalAcara', this.form.tanggalAcara)
      bodyFormData.append('tanggalAkhirAcara', this.form.tanggalAkhirAcara)
      bodyFormData.append('tempatAcara', this.form.tempatAcara)
      bodyFormData.append('lokasiDokumenIa', this.form.lokasiDokumenIa)

      bodyFormData.append('jamMulaiAcara', '00:00:00')
      bodyFormData.append('jamAkhirAcara', '00:00:00')
      await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/tambahTransaksiIa`,
        method: 'POST',
        data: bodyFormData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.$router.push({ name: 'kerjasama.jenisDokumen' })
          this.$toast.success(res.data.message)
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })
    },
    /**
     * Init Data
     */
    async innitData() {}
  },
  data() {
    return {
      /** Required Form */
      requiredForm: [
        { content: 'Nomor Surat UT', model: 'nomorSuratUt' },
        { content: 'Tanggal Surat', model: 'refTanggalSurat' },
        // PIHAK PERTAMA
        { content: 'Nama Pihak Pertama', model: 'namaPihakSatu' },
        { content: 'Jabatan Pihak Pertama', model: 'jabatanPihakSatu' },
        { content: 'Instansi Pihak Pertama', model: 'instansiPihakSatu' },
        { content: 'Alamat Pihak Pertama', model: 'alamatPihakSatu' },
        // PIHAK KEDUA
        { content: 'Nama Pihak Kedua', model: 'namaPihakDua' },
        { content: 'Jabatan Pihak Kedua', model: 'jabatanPihakDua' },
        { content: 'Instansi Pihak Kedua', model: 'instansiPihakDua' },
        { content: 'Alamat Pihak Kedua', model: 'alamatPihakDua' },
        // KEGIATAN
        { content: 'Nama Kegiatan', model: 'namaKegiatan' },
        { content: 'Judul Kegiatan', model: 'judulPks' },
        { content: 'Tanggal Kegiatan', model: 'refTanggalAcara' },
        { content: 'Tanggal Selesai Kegiatan', model: 'refTanggalAkhirAcara' },
        { content: 'Tempat Kegiatan', model: 'tempatAcara' },
        { content: 'File Dokumen', model: 'refLokasiDokumenIa' }
      ],

      isError: {
        berlakuPks: { validBerlakuPks: false },
        berakhirPks: {
          validBerakhirPksFromTanggalKs: false
        }
      },
      // Date Picker
      basicConfig: {
        altInput: true,
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d'
      },
      listInputMitra: [],
      // Form
      form: {
        nomorSuratUt: null,
        nomorSuratMitra: null,
        tanggalSurat: null,
        namaPihakSatu: null,
        jabatanPihakSatu: null,
        instansiPihakSatu: null,
        alamatPihakSatu: null,
        namaPihakDua: null,
        jabatanPihakDua: null,
        instansiPihakDua: null,
        alamatPihakDua: null,
        namaKegiatan: null,
        judulPks: null,
        tanggalAcara: null,
        tanggalAkhirAcara: null,
        tempatAcara: null,
        lokasiDokumenIa: null
      }
    }
  }
}
</script>
